import { gql } from "@apollo/client";

const CheckoutFragment = gql`
  fragment CheckoutFragment on Checkout {
    id
    webUrl
    completedAt
    email
    subtotalPrice {
      amount
      currencyCode
    }
    lineItemsSubtotalPrice {
      amount
    }
    lineItems(first: 100) {
      edges {
        node {
          variant {
            image {
              url(transform: { maxWidth: 100, maxHeight: 100 })
            }
            id
            title
            price {
              amount
            }
            product {
              images(first: 100) {
                edges {
                  node {
                    url(transform: { maxWidth: 100, maxHeight: 100 })
                  }
                }
              }
              id
              title
            }
          }
          quantity
          customAttributes {
            key
            value
          }
          discountAllocations {
            discountApplication {
              ... on DiscountCodeApplication {
                code
              }
            }
            allocatedAmount {
              amount
            }
          }
        }
      }
    }
    discountApplications(first: 100) {
      edges {
        node {
          targetSelection
          ... on DiscountCodeApplication {
            allocationMethod
            applicable
            targetType
            targetSelection
            code
          }
        }
      }
    }
  }
`;

export const CREATE_CART_MUTATION = gql`
  mutation checkoutCreate($input: CheckoutCreateInput!) {
    checkoutCreate(input: $input) {
      checkoutUserErrors {
        field
        message
      }
      checkout {
        id
        webUrl
      }
    }
  }
`;

export const GET_CHECKOUT = gql`
  query GET_CHECKOUT($id: ID!) {
    node(id: $id) {
      ... on Checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const UPDATE_CART_ITEMS_MUTATION = gql`
  mutation checkoutLineItemsReplace(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      userErrors {
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const ADD_CART_ITEM_MUTATION = gql`
  mutation checkoutLineItemsAdd(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
  ) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkoutUserErrors {
        field
        message
      }
      checkout {
        ...CheckoutFragment
      }
    }
  }
  ${CheckoutFragment}
`;

export const ADD_DISCOUNT_CODE_MUTATION = gql`
  mutation checkoutDiscountCodeApplyV2(
    $discountCode: String!
    $checkoutId: ID!
  ) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`;

export const REMOVE_DISCOUNT_CODE_MUTATION = gql`
  mutation checkoutDiscountCodeRemove(
    $discountCode: String!
    $checkoutId: ID!
  ) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`;

export const SET_EMAIL_MUTATION = gql`
  mutation checkoutEmailUpdateV2($checkoutId: ID!, $email: String!) {
    checkoutEmailUpdateV2(checkoutId: $checkoutId, email: $email) {
      checkout {
        ...CheckoutFragment
      }
      checkoutUserErrors {
        code
        field
        message
      }
    }
  }
  ${CheckoutFragment}
`;

export const PRODUCT_DATA_QUERY = gql`
  query PRODUCT_DATA_QUERY($remoteId: String!, $locale: String!) {
    products(where: { _remoteId_contains: $remoteId }, locale: $locale) {
      title
      enable
      remoteId
      variantType
      currency {
        name
        code
        symbol
      }
      variants {
        includeInTracker
        id
        remoteId
        title
        price
        compareAtPrice
        photos {
          url
          height
          width
        }
        shippingSLA
      }
    }
  }
`;

export const PRODUCT_PAGE_DATA_QUERY = gql`
  query PRODUCT_PAGE_DATA_QUERY($productPageId: ID!) {
    productPage(id: $productPageId) {
      title
      enable
      productVariants {
        includeInTracker
        id
        remoteId
        productRemoteId
        title
        price
        compareAtPrice
        media {
          url
          height
          width
        }
        shippingSLA
      }
    }
  }
`;
