import gql from "graphql-tag";
import { CTAFields, FlagFields, ProductCardsFields } from "./fragments";
import { CatalogStackFragments } from "./stack_fragments";

export const CatalogQuery = gql`
  ${CTAFields}
  ${FlagFields}
  ${ProductCardsFields}
  ${CatalogStackFragments}
  query CATALOG_QUERY($locale: String, $catalogSlug: String!) {
    catalogs(locale: $locale, where: { slug: $catalogSlug }) {
      id
      primary
      slug
      breadcrumbTitle
      breadcrumbLink
      headerMedia {
        id
        location
        event
        action
        media {
          url
          width
          height
          alternativeText
        }
        mobileMedia {
          url
          width
          height
          alternativeText
        }
        modal {
          primary
          secondary
          type
          media {
            url
            alternativeText
          }
          additionalTexts {
            text
          }
          ctas {
            text
            event
            location
            type
            rightIcon
            action
          }
        }
        mediaLink
        mediaTranscriptUrl
        pageModalSlug
        media {
          url
          width
          height
        }
      }
      collections {
        primary
        slug
        productSelector {
          id
          product {
            id
            enable
            title
            slug
            variantType
            frame
            productCardDescription
            includeBNPLOptions
            explodeVariants
            currency {
              name
              code
              symbol
            }
            variants {
              enable
              primaryColorHex
              id
              title
              slug
              price
              primaryColorHex
              compareAtPrice
              defaultVariant
              enable
              outOfStock
              productCardGroupLabel
              additionalPriceMessage
              salePriceText
              salePriceTextModal {
                primary
                secondary
                type
                media {
                  url
                  alternativeText
                }
                additionalTexts {
                  text
                }
                ctas {
                  text
                  event
                  location
                  type
                  rightIcon
                  action
                }
              }
              customCta {
                enable
              }
              photos {
                name
                ext
                provider_metadata
                alternativeText
                url
              }
              productShape {
                label
                text
              }
              productFabric {
                label
                text
              }
              productFrame {
                label
                text
              }
              productOption {
                label
                text
              }
              productOption2 {
                label
                text
              }
              filters {
                key
                value
              }
            }
            productCards {
              ...ProductCardsFields
            }
            flags {
              ...FlagFields
            }
            productType {
              name
              label
            }
            productCollection {
              text
              label
            }
            seats
          }
          contentCard {
            link
            action
            useVideoHeight
            modal {
              primary
              secondary
              type
              media {
                url
                alternativeText
              }
              additionalTexts {
                text
              }
              ctas {
                text
                event
                location
                type
                rightIcon
                action
              }
            }
            mediaLink
            mediaTranscriptUrl
            pageModalSlug
            modalMedia {
              url
              width
              height
            }
            sharedGalleries {
              images {
                url
                alternativeText
                caption
                height
                width
                name
              }
            }
            productGallery {
              variants {
                defaultVariant
                photos {
                  url
                  alternativeText
                  caption
                  height
                  width
                  name
                }
                sharedGalleries {
                  images {
                    url
                    alternativeText
                    caption
                    height
                    width
                    name
                  }
                }
              }
            }
            media {
              url
              width
              height
              provider_metadata
              alternativeText
            }
            mobileMedia {
              url
              width
              height
              provider_metadata
              alternativeText
            }
            mediumMedia {
              url
              width
              height
              provider_metadata
              alternativeText
            }
          }
          productPage {
            enable
            description
            title
            slug
            selectors {
              selectorType
              useProductVariantsAsSelectors
              productShapes {
                label
                text
                priority
                media {
                  url
                }
              }
              productFabrics {
                label
                text
                priority
                colorHex
                media {
                  url
                }
              }
              productFrames {
                label
                text
                priority
                media {
                  url
                }
              }
              productOptions {
                label
                text
                priority
                media {
                  url
                }
              }
              productOption2s {
                label
                text
                priority
                media {
                  url
                }
              }
            }
            productVariants {
              id
              additionalPriceMessage
              productCardGroupLabel
              enable
              slug
              title
              price
              outOfStock
              media {
                name
                ext
                provider_metadata
                alternativeText
                url
              }
              compareAtPrice
              salePriceText
              salePriceTextModal {
                primary
                secondary
                type
                media {
                  url
                  alternativeText
                }
                additionalTexts {
                  text
                }
                ctas {
                  text
                  event
                  location
                  type
                  rightIcon
                  action
                }
              }
              customCta {
                enable
              }
              productShape {
                label
                text
              }
              productFabric {
                label
                text
              }
              productFrame {
                label
                text
              }
              productOption {
                label
                text
              }
              productOption2 {
                label
                text
              }
            }
            flags {
              ...FlagFields
            }
            productCards {
              ...ProductCardsFields
            }
          }
        }
      }
      filters {
        properties {
          key
          value
          backgroundImage {
            url
          }
          appliedFilterPresentation
        }
        expanded
        type
        filterGroupTitle
      }
      sorters {
        label
        sorterBy
      }
      stack {
        __typename
        ... on ComponentStackSingleAccordion {
          id
          title
          content
        }
        ... on ComponentStackSeo {
          ...ComponentStackSeoFields
        }
        ... on ComponentStackHero {
          ...ComponentStackHeroFields
        }
        ... on ComponentStackTrustSignalContentBlock {
          ...ComponentStackTrustSignalContentBlockFields
        }
      }
    }
  }
`;
