import gql from "graphql-tag";

export const CTAFields = gql`
  fragment CTAFields on ComponentLayoutLink {
    id
    location
    text
    event
    type
    rightIcon
    action
    textColor
    modal {
      primary
      secondary
      type
      media {
        url
        alternativeText
      }
      additionalTexts {
        text
      }
      ctas {
        text
        event
        location
        type
        rightIcon
        action
      }
    }
    mediaLink
    mediaTranscriptUrl
    pageModalSlug
    media {
      url
      width
      height
    }
    sharedGalleries {
      images {
        url
        alternativeText
        caption
        height
        width
        name
      }
    }
    productGallery {
      variants {
        defaultVariant
        photos {
          url
          alternativeText
          caption
          height
          width
          name
        }
        sharedGalleries {
          images {
            url
            alternativeText
            caption
            height
            width
            name
          }
        }
      }
    }
  }
`;

export const MediaCTAFields = gql`
  fragment MediaCTAFields on ComponentLayoutMediaLink {
    id
    location
    event
    action
    modal {
      primary
      secondary
      type
      media {
        url
        alternativeText
      }
      additionalTexts {
        text
      }
      ctas {
        text
        event
        location
        type
        rightIcon
        action
      }
    }
    mediaLink
    mediaTranscriptUrl
    pageModalSlug
    media {
      url
      alternativeText
      height
      width
      provider_metadata
    }
    mobileMedia {
      url
      alternativeText
      height
      width
      provider_metadata
    }
    sharedGalleries {
      images {
        url
        alternativeText
        caption
        height
        width
        name
      }
    }
    productGallery {
      variants {
        defaultVariant
        photos {
          url
          alternativeText
          caption
          height
          width
          name
        }
        sharedGalleries {
          images {
            url
            alternativeText
            caption
            height
            width
            name
          }
        }
      }
    }
  }
`;

export const BasicProductFields = gql`
  fragment BasicProductFields on Product {
    enable
    remoteId
    description
    id
    title
    slug
    variantType
    includeBNPLOptions
    currency {
      name
      code
      symbol
    }
    variants {
      shippingSLA
      id
      productCardGroupLabel
      remoteId
      enable
      slug
      title
      primaryColorHex
      price
      id
      outOfStock
      ctaAlias
      photos {
        url
        alternativeText
        caption
        height
        width
        name
        provider_metadata
      }
      compareAtPrice
      salePriceText
      salePriceTextModal {
        primary
        secondary
        type
        media {
          url
          alternativeText
        }
        additionalTexts {
          text
        }
        ctas {
          text
          event
          location
          type
          rightIcon
          action
        }
      }
      customCta {
        enable
      }
      productShape {
        label
        text
      }
      productFabric {
        label
        text
      }
      productFrame {
        label
        text
      }
      productOption {
        label
        text
      }
      productOption2 {
        label
        text
      }
    }
    productCards {
      ...ProductCardsFields
    }
    flags {
      ...FlagFields
    }
  }
`;

export const ProductFields = gql`
  fragment ProductFields on Product {
    enable
    remoteId
    description
    id
    title
    slug
    variantType
    variantSelectorTitle
    crossSellSelectorTitle
    disablePromoMessage
    includeBNPLOptions
    seats
    supplemental
    currency {
      name
      code
      symbol
    }
    variants {
      shippingSLA
      purchaseHighlights {
        items {
          primary
          iconSelector {
            media {
              name
              icon {
                url
                alternativeText
                height
                width
                provider_metadata
              }
            }
            width
            height
            color
          }
          cta {
            id
            location
            text
            event
            type
            rightIcon
            action
            textColor
            modal {
              primary
              secondary
              type
              media {
                url
                alternativeText
              }
              additionalTexts {
                text
              }
              ctas {
                text
                event
                location
                type
                rightIcon
                action
              }
            }
            mediaLink
            mediaTranscriptUrl
            pageModalSlug
            media {
              url
              width
              height
            }
            sharedGalleries {
              images {
                url
                alternativeText
                caption
                height
                width
                name
              }
            }
            productGallery {
              variants {
                defaultVariant
                photos {
                  url
                  alternativeText
                  caption
                  height
                  width
                  name
                }
                sharedGalleries {
                  images {
                    url
                    alternativeText
                    caption
                    height
                    width
                    name
                  }
                }
              }
            }
          }
        }
      }
      link {
        ...CTAFields
      }
      miniProductCards {
        title
        cards {
          product {
            enable
            title
            slug
            variantType
            remoteId
            includeBNPLOptions
            currency {
              name
              code
              symbol
            }
            variants {
              id
              enable
              remoteId
              price
              title
              slug
              shippingSLA
              outOfStock
              compareAtPrice
              salePriceText
              photos {
                height
                width
                url
                alternativeText
                caption
                provider_metadata
              }
              thumbImage {
                url
              }
              customCta {
                enable
              }
              productShape {
                label
                text
              }
              productFabric {
                label
                text
              }
              productFrame {
                label
                text
              }
              productOption {
                label
                text
              }
              productOption2 {
                label
                text
              }
            }
          }
          productLinkOnTitle
          hideVariantTitle
          addWithCustomize
          selectionType
          displayVariantSlug
          media {
            url
            alternativeText
          }
          productTitleAlias
          selectorType
          useProductVariantsAsSelectors
          productShapes {
            label
            text
            priority
            media {
              url
            }
          }
          productFabrics {
            label
            text
            priority
            colorHex
            media {
              url
            }
          }
          productFrames {
            label
            text
            priority
            media {
              url
            }
          }
          productOptions {
            label
            text
            priority
            media {
              url
            }
          }
          productOption2s {
            label
            text
            priority
            media {
              url
            }
          }
        }
      }
      id
      productCardGroupLabel
      remoteId
      enable
      slug
      title
      primaryColorHex
      price
      id
      outOfStock
      ctaAlias
      photos {
        url
        alternativeText
        caption
        height
        width
        name
        provider_metadata
      }
      compareAtPrice
      salePriceText
      salePriceTextModal {
        primary
        secondary
        type
        media {
          url
          alternativeText
        }
        additionalTexts {
          text
        }
        ctas {
          text
          event
          location
          type
          rightIcon
          action
        }
      }
      shippingSLA
      sharedGalleries {
        images {
          url
          alternativeText
          caption
          height
          width
          name
          provider_metadata
        }
      }
      customCta {
        text
        location
        event
        chatMessage
        enable
        type
        rightIcon
      }
      dimensionsImage {
        url
      }
      productShape {
        label
        text
      }
      productFabric {
        label
        text
      }
      productFrame {
        label
        text
      }
      productOption {
        label
        text
      }
      productOption2 {
        label
        text
      }
    }
    crossSellProducts {
      id
      product {
        enable
        title
        slug
        variantType
        remoteId
        includeBNPLOptions
        currency {
          name
          code
          symbol
        }
        variants {
          id
          enable
          remoteId
          price
          title
          slug
          shippingSLA
          outOfStock
          compareAtPrice
          salePriceText
          salePriceTextModal {
            primary
            secondary
            type
            media {
              url
              alternativeText
            }
            additionalTexts {
              text
            }
            ctas {
              text
              event
              location
              type
              rightIcon
              action
            }
          }
          photos {
            url
          }
          thumbImage {
            url
          }
          customCta {
            enable
          }
          productShape {
            label
            text
          }
          productFabric {
            label
            text
          }
          productFrame {
            label
            text
          }
          productOption {
            label
            text
          }
          productOption2 {
            label
            text
          }
        }
      }
      productTitleAlias
      displayVariantSlug
      selectionType
      media {
        url
        alternativeText
      }
      selectorType
      useProductVariantsAsSelectors
      productShapes {
        label
        text
        priority
        media {
          url
        }
      }
      productFabrics {
        label
        text
        priority
        colorHex
        media {
          url
        }
      }
      productFrames {
        label
        text
        priority
        media {
          url
        }
      }
      productOptions {
        label
        text
        priority
        media {
          url
        }
      }
      productOption2s {
        label
        text
        priority
        media {
          url
        }
      }
    }
    okendoReview {
      collectionId
      productId
    }
    ctasBlock {
      title
      ctas {
        ...CTAFields
      }
    }
    productCtasBlock {
      title
      ctas {
        ...CTAFields
      }
    }
    productCards {
      ...ProductCardsFields
    }
    flags {
      ...FlagFields
    }
    configurationIcon {
      icon {
        url
      }
    }
    sharedCrossSellProducts {
      clusters {
        id
        product {
          enable
          title
          slug
          variantType
          remoteId
          includeBNPLOptions
          currency {
            name
            code
            symbol
          }
          variants {
            id
            enable
            remoteId
            price
            title
            slug
            shippingSLA
            outOfStock
            compareAtPrice
            salePriceText
            salePriceTextModal {
              primary
              secondary
              type
              media {
                url
                alternativeText
              }
              additionalTexts {
                text
              }
              ctas {
                text
                event
                location
                type
                rightIcon
                action
              }
            }
            photos {
              height
              width
              url
              alternativeText
              caption
              provider_metadata
            }
            customCta {
              enable
            }
            productShape {
              label
              text
            }
            productFabric {
              label
              text
            }
            productFrame {
              label
              text
            }
            productOption {
              label
              text
            }
            productOption2 {
              label
              text
            }
          }
        }
        productTitleAlias
        displayVariantSlug
        selectionType
        media {
          url
          alternativeText
        }
        selectorType
        useProductVariantsAsSelectors
        productShapes {
          label
          text
          priority
          media {
            url
          }
        }
        productFabrics {
          label
          text
          priority
          colorHex
          media {
            url
          }
        }
        productFrames {
          label
          text
          priority
          media {
            url
          }
        }
        productOptions {
          label
          text
          priority
          media {
            url
          }
        }
        productOption2s {
          label
          text
          priority
          media {
            url
          }
        }
      }
    }
    productHighlights {
      items {
        primary
        iconSelector {
          media {
            name
            icon {
              url
              alternativeText
              height
              width
              provider_metadata
            }
          }
          width
          height
          color
        }
        cta {
          id
          location
          text
          event
          type
          rightIcon
          action
          textColor
          modal {
            primary
            secondary
            type
            media {
              url
              alternativeText
            }
            additionalTexts {
              text
            }
            ctas {
              text
              event
              location
              type
              rightIcon
              action
            }
          }
          mediaLink
          mediaTranscriptUrl
          pageModalSlug
          media {
            url
            width
            height
          }
          sharedGalleries {
            images {
              url
              alternativeText
              caption
              height
              width
              name
            }
          }
          productGallery {
            variants {
              defaultVariant
              photos {
                url
                alternativeText
                caption
                height
                width
                name
              }
              sharedGalleries {
                images {
                  url
                  alternativeText
                  caption
                  height
                  width
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FlagFields = gql`
  fragment FlagFields on Flags {
    backgroundColor
    id
    priority
    text
    tooltipDescription
    type
    iconSelector {
      media {
        name
        icon {
          url
          alternativeText
          height
          width
          provider_metadata
        }
      }
      width
      height
      color
    }
  }
`;

export const ProductCardsFields = gql`
  fragment ProductCardsFields on ComponentLayoutProductCard {
    title
    label
    media {
      name
      ext
      provider_metadata
      alternativeText
      url
    }
    variantSlug
    productCardGroupLabel
    selectorType
    useMediaAsHover
    productShapes {
      label
      text
      priority
      media {
        url
      }
    }
    productFabrics {
      label
      text
      priority
      colorHex
      media {
        url
      }
    }
    productFrames {
      label
      text
      priority
      media {
        url
      }
    }
    productOptions {
      label
      text
      priority
      media {
        url
      }
    }
    productOption2s {
      label
      text
      priority
      media {
        url
      }
    }
  }
`;

export const AllFragments = gql`
  ${CTAFields}
  ${ProductFields}
  ${BasicProductFields}
  ${FlagFields}
  ${ProductCardsFields}
`;

export const AllBasicFragments = gql`
  ${MediaCTAFields}
  ${CTAFields}
  ${BasicProductFields}
  ${FlagFields}
  ${ProductCardsFields}
`;
