import React from "react";
import { navigate } from "gatsby-link";
import sanitizeHtml from "sanitize-html";
import showdown from "showdown";
import * as mime from "mime-types";
import { capitalize, map, isArray } from "lodash";

import { deepSave } from "./localDb";
import * as Analytics from "../utils/analytics";
import { namingExceptions } from "../../configs/namingExceptions";
import {
  getLocaleFromPath,
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
} from "../../plugins/gatsby-plugin-outer-i18n";

import { ProductCardDetails } from "../components/ProductVariationCard/ProductVariationCard";
import useLocalization from "../hooks/useLocalization";
import { MediaType } from "../../configs/enums";

const Buffer = require("buffer/").Buffer; // note: the trailing slash is important!

export const standardizeInternalLink = (url: string): string => {
  url = removeTrailingSlash(url);

  // if it is an absoulte url,
  if (isUrlAbsolute(url)) {
    const urlObj = new URL(url);
    return urlObj.pathname;
  } else {
    // if it is not an absolute url
    if (url.charAt(0) === "/") {
      // if it is just a string, add forward slash
      return url;
    } else {
      // if it has a forward slash already, return the string
      return `/${url}`;
    }
  }
  // catch all
  return "";
};

export const formatMarkdown = (str: string, wrap = true) => {
  if (!str) return "";
  const markdownConverter = new showdown.Converter();
  let converted = markdownConverter.makeHtml(str);
  converted = wrap ? converted : converted.replace(/^<p>|<\/p>$/g, "");
  return sanitizeHtml(converted, {
    allowedAttributes: false,
  });
};

export const stripHTML = str =>
  sanitizeHtml(str, { allowedTags: [], allowedAttributes: {} });

// Note: If we use the "actual" locale such as "en-AU", the output will be ${price} instead of A${price}
// Therefore, depending on what product wants currency + price displayed, either populate locale or don't
export const formatPrice = (
  value: number,
  currency: string = "USD",
  roundNearest: boolean = false,
  inCents: boolean = true,
  locale: string = "en"
): string => {
  value = roundNearest ? Math.round(value) : Math.floor(value);
  value = inCents ? value / 100 : value;
  return Number(value).toLocaleString(locale, {
    style: "currency",
    currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
  });
};

export const identifyUser = (email: string): void => {
  Analytics.identify(email, { email });
  deepSave("appState", { email });
};

export const isInternalLink = (url: string): boolean => {
  const ALPHANUMERICDASH_REGEX = /^[a-z0-9-]+[\/[a-z0-9-]+]*$/i;
  // if it is an absolute url, does it contain the hostname
  if (isUrlAbsolute(url)) {
    // does url contain hostname?
    const urlObj = new URL(url);
    if (urlObj.hostname === "liveouter.com") {
      return true;
    } else {
      return false;
    }
  }
  // if it forward slash string (alphanumeric), return true
  if (
    url.charAt(0) === "/" ||
    (url.charAt(0) === "/" && ALPHANUMERICDASH_REGEX.test(url.slice(1)))
  ) {
    return true;
  }

  // if is a string (alphanumeric)
  if (ALPHANUMERICDASH_REGEX.test(url)) {
    return true;
  }

  // catch all - return false
  return false;
};

export const isUrlAbsolute = (url: string): boolean => {
  if (typeof url !== "string") {
    throw new TypeError(`Expected a \`string\`, got \`${typeof url}\``);
  }

  // Don't match Windows paths `c:\`
  if (/^[a-zA-Z]:\\/.test(url)) {
    return false;
  }

  // Scheme: https://tools.ietf.org/html/rfc3986#section-3.1
  // Absolute URL: https://tools.ietf.org/html/rfc3986#section-4.3
  return /^[a-zA-Z][a-zA-Z\d+\-.]*:/.test(url);
};

export const removeTrailingSlash = (str: string): string => {
  if (str.length > 2) return str.replace(/\/$/, "");
  return str;
};

/**
 * Takes an id (usually in the form of remoteId) and encodes it to becomes SKU
 * @param {string} remoteId - the product id can be known as the remote id that is sourced from the CMS
 * Note: Normally the remoteId typically involves the variant.remoteId
 * @param {boolean} variant - a flag used to determine the base string for encoding
 * @returns {string} returns SKU for a givne product / variant
 */
// TO DO - refactor and make changes to other files - variant should be renamed and take a string to support catalog / etc
export const buildSku = (remoteId: string, variant = false): string => {
  if (!remoteId) return null;
  const base = variant
    ? `gid://shopify/ProductVariant/`
    : `gid://shopify/Product/`;
  const plain = base + remoteId.trim();
  // return Buffer.from(plain).toString("base64"); TO-DO: remove after test
  return plain;
};

export const decodeSku = (sku, variant = false) => {
  if (!sku) return null;
  return variant
    ? sku.replace("gid://shopify/ProductVariant/", "")
    : sku.replace("gid://shopify/Product/", "");
};

export const transformShopifyResults = connectionHandle => {
  return connectionHandle && connectionHandle.edges.map(node => node.node);
};

export const triggerChatBubble = message => {
  if (typeof window !== "undefined" && (window as any).Intercom) {
    (window as any).Intercom("show");
    (window as any).Intercom("showNewMessage", message);
  }
};

export const triggerFireworkWidget = () => {
  if (typeof window !== "undefined" && (window as any)._fwn) {
    function startCall() {
      (window as any)._fwn.liveHelper.actions.startCall();
    }
    if ((window as any)._fwn && (window as any)._fwn.liveHelper) {
      // _fwn is available, use the API immediately
      startCall();
    } else {
      // Wait for fw:ready event
      document.addEventListener("fw:ready", () => {
        startCall();
      });
    }
  }
};

export const uniqueId = (): string =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const getLocation = (google, address) =>
  new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      {
        address,
      },
      (results, status) => {
        if (status === "OK") {
          resolve(results);
        } else {
          reject(results);
        }
      }
    );
  });

export const between = (x, min, max) => {
  return x >= min && x <= max;
};

export const isVideoLink = (url: string): boolean => {
  // cloudinary supports multiple video formats for delivery
  // https://cloudinary.com/documentation/video_manipulation_and_delivery#supported_video_formats
  // in the interest of "speed", we're sticking with checking only MP4
  return url.indexOf(".mp4") > -1 ? true : false;
};

export const formatMenu = el => {
  if (!el) el = [];
  const content = el.reduce((acc, el) => {
    const arrContent = {
      ...el.content,
      ...el,
    };

    if (arrContent.content) {
      delete arrContent.content;
    }

    if (arrContent.subMenu) {
      arrContent.subMenu = formatMenu(arrContent.subMenu);
    }

    acc.push(arrContent);

    return acc;
  }, []);

  return { content };
};

export const formatMenuData = menuData => {
  if (!menuData) return [];
  if (isArray(menuData)) {
    return menuData.map(item => {
      if (item.subMenu) {
        item.content = {
          ...item.content,
          subMenu: formatMenuData(item.subMenu),
        };
        delete item.subMenu;
        return item.content;
      }
      return item.content || item;
    });
  }
  return menuData;
};

export const getNavElements = navMenuData =>
  navMenuData.reduce((acc, el) => {
    if (el.showInNav) {
      acc.push(el);
    } else if (el.subMenu?.length) {
      acc = [...acc, ...getNavElements(el.subMenu)];
    }
    return acc;
  }, []);

export const formatDate = (date, dateOptions) => {
  return new Intl.DateTimeFormat("en-US", dateOptions).format(new Date(date));
};

export const i18nizePath = (localeFromState: string, path: string): string => {
  const pathLocale = getLocaleFromPath(path);
  const locale = localeFromState;

  if (locale != DEFAULT_LOCALE && pathLocale == DEFAULT_LOCALE) {
    const localePrependix = `/${locale}`;
    return localePrependix + path;
  }

  return path;
};

export interface NavigateOptions<T> {
  state?: T;
  replace?: boolean;
}

export const navigateTo = <T extends object>(
  locale: string,
  pathname: string,
  navigateOptions?: NavigateOptions<T>
): Promise<void> => {
  if (!isInternalLink(pathname)) {
    return navigate(pathname, navigateOptions);
  }

  const formattedInternalLink = standardizeInternalLink(pathname);
  const localizedPathname = i18nizePath(locale, formattedInternalLink);
  navigate(localizedPathname, navigateOptions);
};

export const addLeadingSlash = (path: string) => {
  if (path === "/") return "";

  if (path.length > 1 && path[0] === "/") {
    return path;
  }

  return "/" + path;
};

export const placeHreflangTags = (path = "") => {
  return SUPPORTED_LOCALES.map(locale => {
    if (locale.toLowerCase() === "en" || locale.toLowerCase() === "en-us") {
      return (
        <link
          key={locale}
          rel="alternate"
          href={`https://liveouter.com${addLeadingSlash(path)}`}
          hrefLang={locale}
        />
      );
    }
    return (
      <link
        key={locale}
        rel="alternate"
        href={`https://liveouter.com/${locale}${addLeadingSlash(path)}`}
        hrefLang={locale}
      />
    );
  });
};

export const createClientSideLocaleBasePath = (code, basepath) => {
  const standardizeBasepath = standardizeInternalLink(basepath);
  return code === "en"
    ? `${standardizeBasepath}`
    : `/:lang${standardizeBasepath}`;
};

export const matchUrlScheme = (url: string): string => {
  if (url.search("mailto:") !== -1) {
    return "email";
  } else if (url.search("tel:") !== -1) {
    return "tel";
  }
  // should catch absolute urls
  return "url";
};

export const extractTrailingUrlScheme = (url: string, schemeType: string) => {
  switch (schemeType) {
    case "email":
      return url.substring(url.indexOf("mailto:") + "mailto:".length);
    case "tel":
      return url.substring(url.indexOf("tel:") + "tel:".length);
    default:
      return url;
  }
};

export const renderLabel = phrase => {
  if (phrase === "aluminum") {
    const { translations } = useLocalization();
    phrase = translations["Filters--aluminum"];
  }

  return namingExceptions[phrase]
    ? namingExceptions[phrase]
    : map(phrase.split(" "), capitalize).join(" ");
};

export const scrollToTop = (height = 0, behavior: ScrollBehavior = "smooth") =>
  window?.scrollTo({
    top: height,
    behavior: behavior,
  });

export const productSlugHelper = (
  catalogSlug: string,
  productData: Partial<ProductData> | ProductCardDetails,
  variantData: Partial<ProductVariantData>,
  canonical = false
): string => {
  // canonical links will always use `catalogSlug` = "products"
  const selectedCatalog = canonical
    ? "products"
    : catalogSlug
    ? catalogSlug
    : "products";
  const selectedVariantSlug =
    productData.variantType === null ? "" : variantData && variantData.slug;
  // returns relative product slug
  return `/${selectedCatalog}/${productData?.slug}/${selectedVariantSlug}`;
};

export const scrollToElement = ({
  element,
  offset = 0,
  behavior = "smooth",
  delay = 500,
}) => {
  return new Promise<void>(resolve => {
    const elementNode = document.querySelector(element);
    const elementPosition = elementNode.offsetTop;
    const offsetPosition = elementPosition - offset;

    setTimeout(() => {
      window?.scrollTo({
        top: offsetPosition,
        left: 0,
        behavior,
      });

      resolve();
    }, delay);
  });
};

export const lastNElements = <T extends unknown>(
  collection: T[],
  n: number
): T[] => {
  return collection.slice(Math.max(collection.length - n, 0));
};

export const elementsAfter = (
  collection: string[],
  criteria: string
): string[] => {
  // limited by first found criteria!!
  const indexOfCriteria = collection.findIndex(element => {
    return element === criteria;
  });

  // slice indexOfCriteria + 1
  if (indexOfCriteria + 1 < collection.length) {
    const newCollection = collection.slice(indexOfCriteria + 1);
    return newCollection;
  }

  // if no matching criteria, return collection
  return collection;
};

export const isCloudinaryUrl = (str: string, type?: MediaType): boolean => {
  if (!str) return false;
  if (type === MediaType.IMAGE) {
    return (
      str.indexOf("https://res.cloudinary.com/outer/image") !== -1 ||
      str.indexOf("http://res.cloudinary.com/outer/image") !== -1
    );
  } else if (type === MediaType.VIDEO) {
    return (
      str.indexOf("https://res.cloudinary.com/outer/video") !== -1 ||
      str.indexOf("http://res.cloudinary.com/outer/video") !== -1
    );
  }
  return (
    str.indexOf("https://res.cloudinary.com/outer/") !== -1 ||
    str.indexOf("http://res.cloudinary.com/outer/") !== -1
  );
};

export const updateUrlParams = (
  param: string,
  key: string,
  operation: "add" | "delete"
) => {
  const params = new URLSearchParams(window.location.search);

  if (
    operation === "add" &&
    params.has(key) &&
    params.getAll(key).includes(param)
  ) {
    return;
  }

  if (operation === "add") {
    params.has(key) ? params.append(key, param) : params.set(key, param);
  } else {
    const keys = params.getAll(key).filter(item => item !== param);
    params.delete(key);
    if (keys.length >= 1) {
      keys.forEach((item, index) => {
        if (index === 0) {
          params.set(key, item);
        } else {
          params.append(key, item);
        }
      });
    }
  }
  history.replaceState(null, null!, "?" + params.toString());
};

export const formatProductAccordionItems = stack =>
  stack
    .reduce((acc, component) => {
      if (component.__typename === "AMAPI_ComponentStackProductAccordion") {
        const accordionItems = component.items.map(item => {
          if (!!item.faq) {
            return {
              title: item.faq.question,
              content: item.faq.answer,
              position: item.entryPosition || "belowCTA",
              enable: item.enable ?? true,
            };
          } else if (!!item.productAccordion) {
            return {
              title: item.productAccordion.title,
              content: item.productAccordion.content,
              position: item.entryPosition || "belowCTA",
              enable: item.productAccordion.enable ?? true,
            };
          } else if (!!item.title && !!item.content) {
            return {
              title: item.title,
              content: item.content,
              position: item.entryPosition || "belowCTA",
              enable: item.enable ?? true,
            };
          }
        });
        return [...acc, ...accordionItems];
      }
      return acc;
    }, [])
    .filter(item => item && item.enable);

export const variantSelectorTitle = type => {
  const { translations } = useLocalization();
  switch (type) {
    case "table":
    case "dining":
      return translations["ColorSelector--header-standard-text"];
    default:
      return translations["ColorSelector--header-text"];
  }
};

export const isVideoMimeType = (uri: string): boolean => {
  const mimeType = mime.lookup(uri);
  if (mimeType) {
    return mimeType?.includes("video");
  }
  return false;
};

export const isImageMimeType = (uri: string): boolean => {
  const mimeType = mime.lookup(uri);
  if (mimeType) {
    return mimeType?.includes("image");
  }
  return false;
};

export const getImageMetadata = (uri: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () => resolve(img);
    img.onerror = () => reject();
    img.src = uri;
    return img;
  });
};

export const extractCloudinaryHeadMetadata = (
  headerStr: string,
  propNamespace: string = "desc"
): Record<string, number> => {
  const metadataObj: Record<string, number> = {};
  const metadataToParse = headerStr
    .split(";")
    .find(data => data.includes("width") && data.includes("height"));

  // convert to easier structure to parse
  const params = new URLSearchParams(metadataToParse);
  const splitParams = params.get(propNamespace).split(",");

  // generate the metadata obj from parsed data
  splitParams.forEach(paramPair => {
    const pairedData = paramPair.split("=");
    metadataObj[pairedData[0]] = parseInt(pairedData[1], 10);
  });

  return metadataObj;
};

export const convertDateToPST = date => {
  return new Date(
    date.toLocaleString("en-US", {
      timeZone: "America/Los_Angeles",
    })
  );
};

export const isAValidVariant = variant => {
  if (!variant) return false;
  return variant.enable && !variant.outOfStock && !variant.customCta?.enable;
};

export const isSafari = () => {
  const chromeInAgent = navigator.userAgent.indexOf("Chrome") > -1;
  const safariInAgent = navigator.userAgent.indexOf("Safari") > -1;
  return safariInAgent && !chromeInAgent;
};

export const getProductsWithValidVariants = products =>
  products.filter(product =>
    product.variants.some(
      v => v.enable && !v.outOfStock && !v.customCta?.enable
    )
  );

export const buildShopifyVariantObj = ({ product, variant, quantity }) => {
  return {
    variantRemoteId: variant.remoteId,
    id: buildSku(variant.remoteId, true),
    productId: buildSku(variant.remoteId, true),
    price: {
      amount: variant.price,
    },
    quantity,
    product: {
      title: product.title,
      id: buildSku(product.remoteId, false),
    },
    title: variant.title,
    customAttributes: [
      {
        key: `_variantId`,
        value: variant.id || "",
      },
      {
        key: `_productPageId`,
        value: product.id || "",
      },
    ],
  };
};

export const buildSelectorsBasedOnVariants = variants =>
  variants.reduce((acc, el, index) => {
    if (acc.some(o => o.text === el.selectorText || o.text === el.title)) {
      return acc;
    }

    const option = {
      label: el.selectorText || el.title || "",
      media:
        el.selectorImage ||
        (el.primaryColorHex && { primaryColorHex: el.primaryColorHex }),
      priority: index,
      text: el.selectorText || el.title || "",
      slug: el.slug,
    };

    const newAcc = [...acc, option];

    return newAcc;
  }, [] as ProductVariantProps[]);

export const videoHasAudio = video => {
  if (!video) return false;
  return (
    video.mozHasAudio ||
    Boolean(video.webkitAudioDecodedByteCount) ||
    Boolean(video.audioTracks && video.audioTracks.length)
  );
};

export const flattenStrapiObject = obj => {
  if (typeof obj === "object" && obj !== null) {
    if (obj.hasOwnProperty("data")) {
      return flattenStrapiObject(obj.data);
    }

    if (obj.hasOwnProperty("attributes")) {
      return flattenStrapiObject(obj.attributes);
    }

    if (Array.isArray(obj)) {
      return obj.map(item => flattenStrapiObject(item));
    }

    const result = {};
    for (const [key, value] of Object.entries(obj)) {
      result[key] = flattenStrapiObject(value);
    }
    return result;
  }
  return obj;
};

export const getAffirmMonthlyPrice = affirmElement => {
  const affirmPrice =
    affirmElement.innerText?.match(/\$(.*?)\/mo/) &&
    affirmElement.innerText?.match(/\$(.*?)\/mo/)[1];
  if (affirmPrice) {
    return `$${affirmPrice}`;
  }
  return undefined;
};

export const customSort = (a, b) => {
  const valueA = a.value;
  const valueB = b.value;

  // Handle ranges like '1-3'
  const parseValue = value => {
    if (value.includes("-")) {
      // For ranges, we only compare the first number in the range
      return parseInt(value.split("-")[0], 10);
    }
    // If it's a number, convert it to an integer; if not, leave as a string
    return isNaN(value) ? value : parseInt(value, 10);
  };

  const parsedA = parseValue(valueA);
  const parsedB = parseValue(valueB);

  // If both are numbers, sort numerically
  if (typeof parsedA === "number" && typeof parsedB === "number") {
    return parsedA - parsedB;
  }

  // Otherwise, sort alphabetically
  return valueA.localeCompare(valueB);
};
