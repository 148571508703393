import gql from "graphql-tag";

export const componentStackSeoFields = gql`
  fragment ComponentStackSeoFields on ComponentStackSeo {
    title
    description
    images {
      url
      width
      height
      alternativeText
    }
    article
  }
`;

export const componentStackTwoColumnsContentBlockFields = gql`
  fragment ComponentStackTwoColumnsContentBlockFields on ComponentStackTwoColumnsContentBlock {
    id
    title
    mediaAlignRight
    primary
    secondary
    mediaArray: media {
      url
      alternativeText
    }
    cta {
      ...CTAFields
    }
  }
`;

export const componentStackPromotedCatalogBlockFields = gql`
  fragment ComponentStackPromotedCatalogBlockFields on ComponentStackPromotedCatalogBlock {
    id
    primary
    secondary
    catalogCard {
      primary
      secondary
      media {
        url
        height
        width
      }
      ctas {
        ...CTAFields
      }
      flags {
        ...FlagFields
      }
    }
  }
`;

export const componentStackPromotedTabbedFeatureFields = gql`
  fragment ComponentStackPromotedTabbedFeatureFields on ComponentStackPromotedTabbedFeature {
    id
    pretitle
    title
    tabs {
      media {
        url
        alternativeText
      }
      title
      primary
      secondary
      cta {
        ...CTAFields
      }
    }
  }
`;

export const componentStackMediaCarouselFields = gql`
  fragment ComponentStackMediaCarouselFields on ComponentStackMediaCarousel {
    id
    title
    carouselItems {
      textAlignment
      id
      primary
      secondary
      textStyle
      cta {
        id
        location
        text
        event
        type
        rightIcon
        chatMessage
      }
      imageBackground {
        url
        alternativeText
        height
        width
        provider_metadata
      }
      videoBackground {
        url
        alternativeText
        caption
        name
      }
      mobileImageBackground {
        url
        alternativeText
        height
        width
        provider_metadata
      }
      mobileVideoBackground {
        url
        alternativeText
        caption
        name
      }
    }
  }
`;

export const componentStackStickyBarFields = gql`
  fragment ComponentStackStickyBarFields on ComponentStackStickyBar {
    id
    primary
    secondary
    cta {
      ...CTAFields
    }
  }
`;

export const componentStackHeroFields = gql`
  fragment ComponentStackHeroFields on ComponentStackHero {
    id
    heightPercent
    mobileHeightPercent
    blackOpacityPercent
    textHero {
      pretitle
      title
      subtitle
      hideSubtitleOnMobile
      showShowroomFinder
      desktopAlignment
      mobileAlignment
      desktopVerticalAlignment
      mobileVerticalAlignment
      emailLeadCaptureForm
      emailLeadCaptureFormList
      media {
        url
        width
        height
        provider_metadata
      }
      mobileMedia {
        url
        width
        height
        provider_metadata
      }
      cta {
        ...CTAFields
      }
    }
    ctaHero {
      media {
        url
        width
        height
        provider_metadata
      }
      mobileMedia {
        url
        width
        height
        provider_metadata
      }
      cta {
        ...CTAFields
      }
    }
    videoHero {
      mediaTranscriptUrl
      media {
        url
        width
        height
        provider_metadata
      }
      mobileMedia {
        url
        width
        height
        provider_metadata
      }
      modalMedia {
        url
        width
        height
        provider_metadata
      }
    }
  }
`;

export const componentStackAccordionFields = gql`
  fragment ComponentStackAccordionFields on ComponentStackAccordion {
    id
    title
    subtitle
    accordion {
      id
      title
      content
      expanded
    }
  }
`;

export const featuredParagraphFields = gql`
  fragment ComponentStackFeaturedParagraphFields on ComponentStackFeaturedParagraph {
    id
    title
    content
    media {
      url
      alternativeText
      caption
      height
      width
      name
    }
  }
`;

export const componentMultiColumnContentBlockFields = gql`
  fragment ComponentStackMultiColumnContentBlockFields on ComponentStackMultiColumnContentBlock {
    id
    title
    backgroundColorHex
    columnsNumber: columns
    media {
      url
      alternativeText
      height
      width
      provider_metadata
    }
    mobileMedia {
      url
      alternativeText
      height
      width
      provider_metadata
    }
    mediaLink
    desktopAlignment
    mobileAlignment
    iconTextBlock {
      primary
      secondary
      image {
        url
        alternativeText
        height
        width
        provider_metadata
      }
      video {
        url
        height
        width
      }
      mediaLink
      cta {
        ...CTAFields
      }
      iconSelector {
        media {
          name
          icon {
            url
            alternativeText
            height
            width
            provider_metadata
          }
        }
        width
        height
        color
      }
    }
    sharedContent {
      title
      backgroundColorHex
      columnsNumber: columns
      media {
        mainMedia {
          url
          alternativeText
          height
          width
          provider_metadata
        }
        mobileMedia {
          url
          alternativeText
          height
          width
          provider_metadata
        }
      }
      mediaLink
      desktopAlignment
      mobileAlignment
      iconTextBlock {
        primary
        secondary
        image {
          url
          alternativeText
          height
          width
          provider_metadata
        }
        video {
          url
          height
          width
        }
        mediaLink
        cta {
          ...CTAFields
        }
        iconSelector {
          media {
            name
            icon {
              url
              alternativeText
              height
              width
              provider_metadata
            }
          }
          width
          height
          color
        }
      }
    }
  }
`;

export const componentStackTextBlockFields = gql`
  fragment ComponentStackTextBlockFields on ComponentStackTextBlock {
    id
    content
    backgroundColorHex
  }
`;

export const componentStackFireworkVideosFields = gql`
  fragment ComponentStackFireworkVideosFields on ComponentStackFireworkVideos {
    title
    subtitle
    playlist
  }
`;

// Note the ProductCardFields is used in the componentStackMerchandisingBlock, however it can't not be used in the deepest level and it is duplicated

export const productCardFields = gql`
  fragment ProductCardFields on Product {
    id
    enable
    remoteId
    description
    title
    slug
    variantType
    currency {
      name
      code
      symbol
    }
    variants {
      remoteId
      enable
      slug
      title
      primaryColorHex
      price
      id
      outOfStock
      photos {
        url
        alternativeText
        caption
        height
        width
        name
      }
      compareAtPrice
      salePriceText
    }
    okendoReview {
      collectionId
      productId
    }
    flags {
      backgroundColor
      id
      priority
      text
      tooltipDescription
      type
      iconSelector {
        media {
          name
          icon {
            url
            alternativeText
            height
            width
            provider_metadata
          }
        }
        width
        height
        color
      }
    }
  }
`;

export const componentStackMerchandisingBlockFields = gql`
  fragment ComponentStackMerchandisingBlockFields on ComponentStackMerchandisingBlock {
    id
    title
    showFilters
    backgroundColorHex
    productCollections {
      primary
      displayVariantSlug
      productCardLabelSelector
      products {
        ...BasicProductFields
      }
      productCollection {
        productSelector {
          product {
            ...BasicProductFields
          }
        }
      }
    }
    sharedContent {
      title
      showFilters
      backgroundColorHex
      productCollections {
        primary
        displayVariantSlug
        productCardLabelSelector
        products {
          ...BasicProductFields
        }
        productCollection {
          productSelector {
            product {
              ...BasicProductFields
            }
          }
        }
      }
    }
  }
`;

export const componentStackSpacerFields = gql`
  fragment ComponentStackSpacerFields on ComponentStackSpacer {
    backgroundColor
    desktopValue
    mobileValue
  }
`;

export const componentStackStaticTestimonialBlockFields = gql`
  fragment ComponentStackStaticTestimonialBlockFields on ComponentStackStaticTestimonialBlock {
    id
    title
    testimonialItem {
      topIcon
      title
      content
      name
      location
      testimonial {
        title
        content
        name
        location
      }
      iconSelector {
        media {
          name
          icon {
            url
            alternativeText
            height
            width
            provider_metadata
          }
        }
        width
        height
        color
      }
    }
  }
`;

export const componentStackCtasFields = gql`
  fragment ComponentStackCtasFields on ComponentStackCtas {
    ctas {
      ...CTAFields
    }
  }
`;

export const componentStackPressLogosFields = gql`
  fragment ComponentStackPressLogosFields on ComponentStackPressLogos {
    pressLogoItem {
      link
      iconSelector {
        media {
          name
          icon {
            url
            alternativeText
            height
            width
            provider_metadata
          }
        }
        width
        height
        color
      }
    }
    primary
    backgroundColorHex
  }
`;

export const componentStackContactSectionFields = gql`
  fragment ComponentStackContactSectionFields on ComponentStackContactSection {
    primary
    secondary
    useMainHeaders
    contactItems {
      type
      primary
      secondary
    }
  }
`;

export const componentTrustSignalContentBlockFields = gql`
  fragment ComponentStackTrustSignalContentBlockFields on ComponentStackTrustSignalContentBlock {
    id
    backgroundColorHex
    columns
    desktopAlignmentAlias: desktopAlignment
    mobileAlignmentAlias: mobileAlignment
    iconTextBlock {
      primary
      iconSelector {
        media {
          name
          icon {
            url
            alternativeText
            height
            width
            provider_metadata
          }
        }
        width
        height
        color
      }
      cta {
        ...CTAFields
      }
    }
    sharedContent {
      backgroundColorHex
      columns
      desktopAlignmentAlias: desktopAlignment
      mobileAlignmentAlias: mobileAlignment
      iconTextBlock {
        primary
        iconSelector {
          media {
            name
            icon {
              url
              alternativeText
              height
              width
              provider_metadata
            }
          }
          width
          height
          color
        }
        cta {
          ...CTAFields
        }
      }
    }
  }
`;

export const componentStackCardScrollBlockFields = gql`
  fragment ComponentStackCardScrollBlockFields on ComponentStackCardScrollBlock {
    id
    title
    titleTagAlias: titleTag
    landscapeMedia
    description
    style
    backgroundColorHex
    cards {
      title
      titleTag
      description
      link
      media {
        url
        alternativeText
        height
        width
        provider_metadata
      }
      flags {
        ...FlagFields
      }
    }
  }
`;

export const componentStackProductPimDimensionsFields = gql`
  fragment ComponentStackProductPimDimensionsFields on ComponentStackProductPimDimensions {
    id
    sharedProductDimensions {
      title
      pimItems {
        pimId
        titleAlias
        pimProperties
        keyValueEntry {
          key
          value
        }
      }
    }
  }
`;

export const componentStackSpecificationTabsFields = gql`
  fragment ComponentStackSpecificationTabsFields on ComponentStackSpecificationTabs {
    id
    specsTabs {
      expandedInMobile
      productSpecification {
        name
        media {
          url
          alternativeText
          height
          width
          provider_metadata
        }
        description
        specs {
          primary
          iconSelector {
            media {
              name
              icon {
                url
                alternativeText
                height
                width
                provider_metadata
              }
            }
            width
            height
            color
          }
          cta {
            ...CTAFields
          }
        }
      }
    }
    title
    mobileHexBackground
    specsLink
  }
`;

export const componentStackCollectionsBlockFields = gql`
  fragment ComponentStackCollectionsBlockFields on ComponentStackCollectionsBlock {
    id
    title
    titleTag
    cta {
      ...CTAFields
    }
    collectionSection {
      type
      collectionCard {
        title
        titleTag
        description
        textAboveImageOnMobile
        media {
          ...MediaCTAFields
        }
        cta {
          ...CTAFields
        }
        collectionGrid {
          title
          description
          mediaCta {
            ...MediaCTAFields
          }
        }
        productHighlights {
          items {
            primary
            iconSelector {
              media {
                name
                icon {
                  url
                  alternativeText
                  height
                  width
                  provider_metadata
                }
              }
              width
              height
              color
            }
            cta {
              id
              location
              text
              event
              type
              rightIcon
              action
              textColor
              modal {
                primary
                secondary
                type
                media {
                  url
                  alternativeText
                }
                additionalTexts {
                  text
                }
                ctas {
                  text
                  event
                  location
                  type
                  rightIcon
                  action
                }
              }
              mediaLink
              mediaTranscriptUrl
              pageModalSlug
              media {
                url
                width
                height
              }
              sharedGalleries {
                images {
                  url
                  alternativeText
                  caption
                  height
                  width
                  name
                }
              }
              productGallery {
                variants {
                  defaultVariant
                  photos {
                    url
                    alternativeText
                    caption
                    height
                    width
                    name
                  }
                  sharedGalleries {
                    images {
                      url
                      alternativeText
                      caption
                      height
                      width
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const AllFragments = gql`
  ${componentStackSeoFields}
  ${componentStackTwoColumnsContentBlockFields}
  ${componentStackPromotedCatalogBlockFields}
  ${componentStackPromotedTabbedFeatureFields}
  ${componentStackMediaCarouselFields}
  ${componentStackStickyBarFields}
  ${componentStackHeroFields}
  ${componentStackAccordionFields}
  ${featuredParagraphFields}
  ${componentMultiColumnContentBlockFields}
  ${componentStackTextBlockFields}
  ${componentStackFireworkVideosFields}
  ${componentStackMerchandisingBlockFields}
  ${componentStackSpacerFields}
  ${componentStackStaticTestimonialBlockFields}
  ${componentStackCtasFields}
  ${componentStackPressLogosFields}
  ${componentStackContactSectionFields}
  ${componentTrustSignalContentBlockFields}
  ${componentStackCardScrollBlockFields}
  ${componentStackProductPimDimensionsFields}
  ${componentStackSpecificationTabsFields}
  ${componentStackCollectionsBlockFields}
`;

export const AllPdpFragments = gql`
  ${componentStackSeoFields}
  ${componentStackTwoColumnsContentBlockFields}
  ${componentStackPromotedCatalogBlockFields}
  ${componentStackPromotedTabbedFeatureFields}
  ${componentStackMediaCarouselFields}
  ${componentStackStickyBarFields}
  ${componentStackHeroFields}
  ${componentStackAccordionFields}
  ${featuredParagraphFields}
  ${componentMultiColumnContentBlockFields}
  ${componentStackTextBlockFields}
  ${componentStackFireworkVideosFields}
  ${componentStackMerchandisingBlockFields}
  ${componentStackSpacerFields}
  ${componentStackStaticTestimonialBlockFields}
  ${componentStackCtasFields}
  ${componentStackPressLogosFields}
  ${componentStackContactSectionFields}
  ${componentTrustSignalContentBlockFields}
  ${componentStackCardScrollBlockFields}
  ${componentStackProductPimDimensionsFields}
  ${componentStackSpecificationTabsFields}
`;

export const CatalogStackFragments = gql`
  ${componentStackSeoFields}
  ${componentStackHeroFields}
  ${componentTrustSignalContentBlockFields}
`;
